import { Box } from "@mui/material";
import CmsManagedImage from "components/cms/cmsManagedImage/cmsManagedImage";
import { useTranslation } from "next-i18next";
import Link from "next/link";

// Note: mlogo, url = logoUrl (linked site)
const NavbarBrand = ({ mlogo, url, width, height, hide }) => {
  const { t: tCms } = useTranslation("cms");

  const baseSize = 50;
  const maxHeight = 70;

  // base size to calculate the image aspect ratio is 50px
  const getDefaultImageRatio = (mlogo, direction) => {
    if (mlogo) {
      if (direction === "width") {
        if (mlogo.file.width > mlogo.file.height) {
          return Math.round((mlogo.file.width / mlogo.file.height) * baseSize);
        } else {
          return baseSize;
        }
      } else {
        if (mlogo.file.height > mlogo.file.width) {
          return Math.round((mlogo.file.height / mlogo.file.width) * baseSize) < maxHeight
            ? Math.round((mlogo.file.height / mlogo.file.width) * baseSize)
            : maxHeight;
        } else {
          return baseSize;
        }
      }
    }
  };

  return (
    <>
      <Box
        className={`${hide ? "d-none " : ""}navigation-brand`}
        sx={{
          display: "flex",
          maxWidth: "100%",
          minWidth: width || getDefaultImageRatio(mlogo, "width")
        }}
      >
        {mlogo ? (
          url ? (
            <Link href={url ? `${url}` : null} passHref>
              <a>
                <CmsManagedImage
                  img={mlogo}
                  objectFit="cover"
                  height={height || getDefaultImageRatio(mlogo, "height")}
                  width={width || getDefaultImageRatio(mlogo, "width")}
                  alt={
                    url == "/"
                      ? tCms("navigation-routeToHomepage")
                      : tCms("navigation-routeToPage", { page: url })
                  }
                />
              </a>
            </Link>
          ) : (
            <CmsManagedImage
              img={mlogo}
              height={height || getDefaultImageRatio(mlogo, "height")}
              width={width || getDefaultImageRatio(mlogo, "width")}
              alt="logo"
            />
          )
        ) : null}
      </Box>
    </>
  );
};

export default NavbarBrand;
