import { useRouter } from "next/router";
import { useMemo } from "react";
import { globalSettings } from "services/globalSettings/globalSettingsService";
import {
  convertImageMaxDimension,
  createImageDimensionObject,
  getMfileAltTextOrTitle,
  renderImage,
} from "utils/util";

/**
 * height and width can be objects that contain different height and width depending on the view port.
 * The height or width object need the following structur and keys:
 * - height/width object: {mobile, tablet, desktop, wqhd}
 * - props.alignment options: "left" or "right" else the image is centered
 * - props.objectFit default: "contain"
 * - maxHeight and maxWidth default: "100%""
 * @param {Object} props
 * @param {String} props.alt
 * @param {String} props.title
 * @param {Number | String} props.maxHeight
 * @param {Number | String} props.maxWidth
 * @param {String} props.objectFit
 * @param {String} props.alignment
 * @param {Number | String | object} props.height
 * @param {Number | String | object} props.width
 * @param {String} props.img
 */
export default function CmsManagedImage(props) {
  const router = useRouter();
  const cssHeight = useMemo(
    () => createImageDimensionObject(props.height),
    [props.height]
  );
  const cssWidth = useMemo(
    () => createImageDimensionObject(props.width),
    [props.width]
  );
  //takes the maxHeight and maxWidth props an converts them in a usable format for css
  const maxHeight = useMemo(
    () => convertImageMaxDimension(props.maxHeight),
    [props.maxHeight]
  );
  const maxWidth = useMemo(
    () => convertImageMaxDimension(props.maxWidth),
    [props.maxWidth]
  );

  // TRM-99:
  // console.log(maxHeight);

  // TBD/TODO: There might be cases where imgHeight/imgWidth is used.
  // Until we rebuild cmsFixedImage & cmsManagedImage (see TRM-99 comments) this part is unchanged.
  let imgHeight = props.img && props.img.file && props.img.file.height;
  let imgWidth = props.img && props.img.file && props.img.file.width;
  // eslint-disable-next-line react/display-name
  return (
    <>
      <img
        onLoad={props.onLoad ? (e) => props.onLoad(e) : null}
        className="cms-managed-image"
        src={props.img ? renderImage(props.img) : "/image-placeholder.svg"}
        alt={getMfileAltTextOrTitle("altText", props.img, router.locale)}
        title={getMfileAltTextOrTitle("title", props.img, router.locale)}
        height={imgHeight}
        width={imgWidth}
        ref={props.imageRef || null}
      />
      <style jsx>{`
        .cms-managed-image {
          margin-left: ${props.alignment === "left" ? 0 : "auto"};
          margin-right: ${props.alignment === "right" ? 0 : "auto"};

          object-fit: ${props.objectFit ? props.objectFit : "contain"};
          object-position: ${props.objectFit !== "contain" &&
          props.alignment === "left"
            ? "0% 50%"
            : props.objectFit !== "contain" && props.alignment === "right"
            ? "100% 50%"
            : "50% 50%"};

          width: ${cssWidth.mobile};
          height: ${cssHeight.mobile};
          max-width: ${maxWidth.mobile};
          max-height: ${maxHeight.mobile};

          @media (min-width: ${globalSettings.responsive.breakpointmobile}px) {
            width: ${cssWidth.tablet};
            height: ${cssHeight.tablet};
            max-width: ${maxWidth.tablet};
            max-height: ${maxHeight.tablet};
          }

          @media (min-width: ${globalSettings.responsive.breakpointdesktop}px) {
            width: ${cssWidth.desktop};
            height: ${cssHeight.desktop};
            max-width: ${maxWidth.desktop};
            max-height: ${maxHeight.desktop};
          }

          @media (min-width: ${globalSettings.responsive.breakpoint2k}px) {
            width: ${cssWidth.wqhd};
            height: ${cssHeight.wqhd};
            max-width: ${maxWidth.wqhd};
            max-height: ${maxHeight.wqhd};
          }
        }
      `}</style>
    </>
  );
}
